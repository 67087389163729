import React, { useEffect, useRef } from "react"
import { NotFound404Props } from "./NotFound404.d"
import Lottie from "lottie-web"
import reasearchAnimation from "./research.json"
import tw from "twin.macro"

const NotFound404: React.FC<NotFound404Props> = ({}) => {
  const animationRef = useRef(null)

  useEffect(() => {
    Lottie.loadAnimation({
      container: animationRef?.current,
      animationData: reasearchAnimation,
      renderer: "svg",
    })
  }, [])

  return (
    <section css={[tw`py-20 max-w-5xl mx-auto px-8`]}>
      <div css={[tw`h-80`]} ref={animationRef} />
      <h2
        css={[
          tw`w-full md:w-1/2 mx-auto text-center font-crimson-pro text-3xl font-bold`,
        ]}
      >
        We're sorry. Looks like the page you are looking for does not exist.
      </h2>
    </section>
  )
}

export default NotFound404
