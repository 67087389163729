import * as React from "react"
import { Layout } from "../components/organisms/Layout"
import { NotFound404 } from "../components/organisms/NotFound404"
import { Hero } from "../components/organisms/Hero"

const NotFoundPage = () => {
  return (
    <>
      <Layout>
        <Hero
          gradient={{
            color1: {
              hex: "#d30000",
            },
            color2: {
              hex: "#10069F",
            },
          }}
          heading="Page not found"
          heroType="halfPage"
        />
        <NotFound404 />
      </Layout>
    </>
  )
}

export default NotFoundPage
